@font-face {
  font-family: 'Mark';
  font-weight: 400;
  src: url(https://fonts.thumbtack.com/mark/mark-tt-subset.woff2) format('woff2'),
    url(https://fonts.thumbtack.com/mark/mark-tt-subset.woff) format('woff');
}

@font-face {
  font-family: 'Mark';
  font-weight: 700;
  src: url(https://fonts.thumbtack.com/mark/mark-tt-subset-bold.woff2) format('woff2'),
    url(https://fonts.thumbtack.com/mark/mark-tt-subset-bold.woff) format('woff');
}

.tt-dashed-line-background {
  background-image: url(/images/affiliates/dashed-line.png)
}